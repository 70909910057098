// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"evo:000000.1-00000"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import {
	browserTracingIntegration,
	captureConsoleIntegration,
	httpClientIntegration,
	init,
	replayIntegration,
} from '@sentry/nextjs';

if (process.env.SENTRY_ENABLED === 'true') {
	init({
		dsn: process.env.SENTRY_DSN,

		release: process.env.SENTRY_RELEASE,

		environment: process.env.SENTRY_ENV,

		// Add optional integrations for additional features
		integrations: [
			replayIntegration(),
			httpClientIntegration(),
			browserTracingIntegration(),
			captureConsoleIntegration(),
		],

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		// This option is required for capturing headers and cookies. required for httpClientIntegration()
		sendDefaultPii: true,

		// Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
		tracesSampleRate: 1,

		replaysSessionSampleRate: 0.3, // let's start with 0.5 for each environment and adjust later

		// Define how likely Replay events are sampled when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		initialScope: (scope) => {
			scope.setTags({ [`evo.tier`]: 'client' });
			return scope;
		},
	});
}
